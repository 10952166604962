<template>
  <div id="app" :class="wrap">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
const prefixCls = localStorage.getItem('lang') === 'en-US' ? 'en-tongtool' : ''
export default {
  data() {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    wrap() {
      return prefixCls
    }
  },
  //刷新路由
  provide() {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    }
  }
}
</script>
