import { RouteConfig } from 'vue-router'

export const statistics: RouteConfig[] = [
  {
    path: '/statistics',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/statistics/storeStatistics', // 店鋪分析
        component: () => import('@/views/statistics/storeStatistics.vue')
      },
      {
        path: '/statistics/orderStatistics', // 订单分析
        component: () => import('@/views/statistics/orderStatistics.vue')
      },
      {
        path: '/statistics/customerStatistics', // 客戶分析
        component: () => import('@/views/statistics/customerStatistics.vue')
      },
      {
        path: '/statistics/commodityStatistics', // 商品销量分析
        component: () => import('@/views/statistics/commodityStatistics.vue')
      },
      {
        path: '/statistics/inAndOutOfWarehouseStatistics', // 出入庫管理
        component: () =>
          import('@/views/statistics/inAndOutOfWarehouseStatistics.vue')
      },
      {
        path: '/statistics/commodityWarehouseStatistics', // 商品庫存分析
        component: () =>
          import('@/views/statistics/commodityWarehouseStatistics.vue')
      },
      {
        path: '/statistics/amazon/compensation', // 亞馬遜-賠償
        component: () => import('@/views/statistics/amazon/compensation.vue')
      },
      {
        path: '/statistics/amazon/inventory', // 亞馬遜-盤存
        component: () => import('@/views/statistics/amazon/inventory.vue')
      },
      {
        path: '/statistics/amazon/monthlyStorageFee', // 亞馬遜-月度倉儲費
        component: () =>
          import('@/views/statistics/amazon/monthlyStorageFee.vue')
      },
      {
        path: '/statistics/amazon/longItemStorageFee', // 亞馬遜-長期倉儲費
        component: () =>
          import('@/views/statistics/amazon/longItemStorageFee.vue')
      },
      {
        path: '/statistics/bestSellingStore', // 最暢銷店鋪
        component: () => import('@/views/statistics/bestSellingStore.vue')
      },
      {
        path: '/statistics/bestSellingGoods', // 最暢銷商品
        component: () => import('@/views/statistics/bestSellingGoods.vue')
      },
      {
        path: '/statistics/storeSalesConditionsStatistics', // 店鋪統計
        component: () =>
          import('@/views/statistics/storeSalesConditionsStatistics.vue')
      },
      {
        path: '/statistics/profitAnalysis', // 利潤分析
        component: () => import('@/views/statistics/profitAnalysis.vue')
      },
      {
        path: '/statistics/supplierPerformanceAnalysis', // 廠商業績分析
        component: () =>
          import('@/views/statistics/supplierPerformanceAnalysis.vue')
      },
      {
        path: '/statistics/purchasingAgentAnalysis', // 廠商業績分析
        component: () =>
          import('@/views/statistics/purchasingAgentAnalysis.vue')
      }
    ]
  }
]
