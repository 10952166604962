import { RouteConfig } from 'vue-router'

export const warehouse: RouteConfig[] = [
  {
    path: '/warehouse',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/warehouse/commodityStock', // 商品庫存
        component: () => import('@/views/warehouse/commodityStock.vue')
      },
      {
        path: '/warehouse/commodityStock/task', // 商品庫存
        component: () => import('@/components/task.vue')
      },
      {
        path: '/warehouse/inventoryVerification', // 庫存盤點
        component: () => import('@/views/warehouse/inventoryVerification.vue')
      },
      {
        path: '/warehouse/transactionOrder', // 庫存異動單
        component: () => import('@/views/warehouse/transactionOrder.vue')
      },
      {
        path: '/warehouse/transactionOrder/details/:id', // 查看庫存移動單
        component: () => import('@/views/warehouse/transactionOrderDetail.vue')
      },
      {
        path: '/warehouse/rework', // 庫存返修
        component: () => import('@/views/warehouse/rework.vue')
      },
      {
        path: '/warehouse/rework/details/:id', // 庫存返修
        component: () => import('@/views/warehouse/reworkDetail.vue')
      },
      {
        path: '/warehouse/rework/create', // 新增庫存返修
        component: () => import('@/views/warehouse/createRework.vue')
      },
      {
        path: '/warehouse/rework/arrived/:id', // 返修收货
        component: () => import('@/views/warehouse/reworkArrived.vue')
      },
      {
        path: '/warehouse/goodsCommoditiesAssemble', // 組合品裝配
        component: () =>
          import('@/views/warehouse/goodsCommoditiesAssemble.vue')
      },
      {
        path: '/warehouse/goodsCommoditiesAssemble/details/:id', // 組合品裝配詳情
        component: () =>
          import('@/views/warehouse/goodsCommoditiesAssembleDetails.vue')
      },
      {
        path: '/warehouse/goodsCommoditiesAssemble/split/details/:id', // 拆分单详情
        component: () =>
          import('@/views/warehouse/goodsCommoditiesAssembleDetails.vue')
      },
      {
        path: '/warehouse/goodsCommoditiesAssemble/create', // 新增組合品裝配
        component: () =>
          import('@/views/warehouse/goodsCommoditiesAssembleCreate.vue')
      },
      {
        path: '/warehouse/rework/task', // 庫存返修導出任務
        component: () => import('@/components/task.vue')
      },
      {
        path: '/warehouse/moveOrder', // 移庫單
        component: () => import('@/views/warehouse/moveOrder.vue')
      },
      {
        path: '/warehouse/moveOrder/details/:id', // 移庫單詳情
        component: () => import('@/views/warehouse/moveOrderDetail.vue')
      },
      {
        path: '/warehouse/moveOrder/create', // 新增庫存返修
        component: () => import('@/views/warehouse/createMoveOrder.vue')
      },
      {
        path: '/warehouse/rework/details/:id', // 庫存返修查看
        component: () => import('@/views/warehouse/reworkDetail.vue')
      },
      {
        path: '/warehouse/transactionOrder/create', // 新增庫存異動單
        component: () =>
          import('@/views/warehouse/createNewTransactionOrder.vue')
      },
      {
        // export and import task
        path: '/warehouse/inventoryVerification/task',
        component: () => import('@/components/task.vue')
      },
      {
        path: '/warehouse/salesReturn', // 退貨
        component: () => import('@/views/warehouse/salesReturn.vue')
      },
      {
        path: '/warehouse/salesReturn/orderDetails/:id', // 訂單詳情
        component: () => import('@/views/order/orderDetails.vue')
      },
      {
        // export and import task
        path: '/warehouse/salesReturn/task',
        component: () => import('@/components/task.vue')
      },
      {
        path: '/warehouse/mulCommoditiesStock', // 組合品庫存
        component: () => import('@/views/warehouse/mulCommoditiesStock.vue')
      },
      {
        path: '/warehouse/assistant', // 庫存助手
        component: () => import('@/views/warehouse/stockAssistant.vue')
      },
      {
        path: '/warehouse/assistant/shopSkuBindingStockSku', // 店鋪SKU關聯庫存SKU
        component: () => import('@/views/warehouse/shopSkuBindingStockSku.vue')
      }
    ]
  }
]
