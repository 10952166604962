import { RouteConfig } from 'vue-router'
const routeModules = require.context('../routes', true, /.index.ts$/)
let array: RouteConfig[] = []
routeModules.keys().forEach((key: string) => {
  let fileName = key.split('/')[1]
  if (routeModules(key)[fileName]) {
    array = array.concat(routeModules(key)[fileName])
  }
})
const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/',
        component: () => import('@/views/index.vue')
      },
      {
        path: '/noPermission',
        component: () => import('@/components/noPermission.vue')
      }
    ]
  },
  {
    path: '/direct',
    component: () => import('@/views/direct.vue') // 导航页
  },
  ...array
]

export default routes
