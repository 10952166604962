import { RouteConfig } from 'vue-router'

export const afterSale: RouteConfig[] = [
  {
    path: '/afterSale',
    redirect: '/afterSale/returnGoods',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/afterSale/returnGoods', // 售後服務/退貨
        name: 'afterSale_returnGoods',
        component: () => import('@/views/afterSale/returnGoods.vue')
      },
      {
        path: '/afterSale/exchange', // 售後服務/換貨
        name: 'afterSale_exchange',
        component: () => import('@/views/afterSale/exchange.vue')
      },
      {
        path: '/afterSale/repair', // 售後服務/維修
        name: 'afterSale_repair',
        component: () => import('@/views/afterSale/repair.vue')
      },
      {
        path: '/afterSale/notTaken', // 售後服務/未取
        name: 'afterSale_notTaken',
        component: () => import('@/views/afterSale/notTaken.vue')
      },
      {
        path: '/afterSale/returnGoods/details/:id', // 售後服務/退貨詳情
        name: 'afterSale_returnGoodsDetails',
        component: () =>
          import('@/views/afterSale/components/returnGoodsDetails.vue')
      },
      {
        path: '/afterSale/exchange/details/:id', // 售後服務/換貨詳情
        name: 'afterSale_exchangeDetails',
        component: () =>
          import('@/views/afterSale/components/exchangeDetails.vue')
      },
      {
        path: '/afterSale/repair/details/:id', // 售後服務/維修詳情
        name: 'afterSale_repairDetails',
        component: () =>
          import('@/views/afterSale/components/repairDetails.vue')
      },
      {
        path: '/afterSale/warrantyListPool', // 保修卡池
        name: 'afterSale_warrantyListPool',
        component: () => import('@/views/afterSale/warrantyListPool.vue')
      }
    ]
  }
]
