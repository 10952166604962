import { RouteConfig } from 'vue-router'

export const product: RouteConfig[] = [
  {
    path: '/product',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/product/dataCollection',
        component: () => import('@/views/product/dataCollection.vue')
      },
      {
        path: '/product/waterMark', // 水印模板列表
        component: () => import('@/views/product/waterMark/list.vue')
      },
      {
        path: '/product/waterMark/create', // 新增水印模板
        component: () => import('@/views/product/waterMark/create.vue')
      },
      {
        path: '/product/waterMark/edit', // 编辑水印模板
        component: () => import('@/views/product/waterMark/create.vue')
      },
      {
        path: '/product/ebayListing/online', // 刊登在线
        component: () => import('@/views/product/ebay/listingOnline.vue')
      },
      {
        path: '/product/ebayListing/draft', // 刊登草稿
        component: () => import('@/views/product/ebay/listingDraft.vue')
      },
      {
        path: '/product/ebayListing/online/details', // 在線详情
        component: () => import('@/views/product/ebay/listingOnlineDetails.vue')
      },
      {
        path: '/product/ebayListing/draft/details', // 草稿详情
        alias: '/product/ebayListing/draft/create',
        component: () => import('@/views/product/ebay/listingOnlineDetails.vue')
      },
      {
        path: '/product/move/lazada', // 产品搬家
        alias: '/product/houseMoving',
        component: () => import('@/views/product/move/lazada.vue')
      },
      {
        path: '/product/move/shopee',
        component: () => import('@/views/product/move/shopee.vue')
      },
      {
        path: '/product/move/ebay', // ebay产品搬家
        component: () => import('@/views/product/move/ebay.vue')
      },
      {
        path: '/product/move/lazada/categoryMapping', // 類目映射
        alias: '/product/move/ebay/categoryMapping',
        component: () => import('@/views/product/move/categoryMapping.vue')
      },
      {
        path: '/product/move/shopify', // shopify产品搬家
        alias: '/product/move/list',
        component: () => import('@/views/product/move/shopify.vue')
      },
      {
        path: '/product/move/attributeExchangeRules', // 产品搬家-屬性替換規則
        alias: '/product/houseMoving/attributeExchangeRules',
        component: () => import('@/views/product/attributeExchangeRules.vue')
      },
      {
        path: '/product/ebayListing/listingTemplate/paymentPolicy', // 付款政策
        alias: '/product/ebayListing/listingTemplate/',
        component: () =>
          import('@/views/product/ebay/commonTemplate/paymentPolicy.vue')
      },
      {
        path: '/product/ebayListing/listingTemplate/paymentPolicy/detail', // 付款政策新增/編輯
        alias: '/product/ebayListing/listingTemplate/paymentPolicy/create',
        component: () =>
          import('@/views/product/ebay/commonTemplate/paymentPolicyDetails.vue')
      },
      {
        path: '/product/ebayListing/listingTemplate/shippingPolicy', // 運輸政策
        component: () =>
          import('@/views/product/ebay/commonTemplate/shippingPolicy.vue')
      },
      {
        path: '/product/ebayListing/listingTemplate/shippingPolicy/detail', // 運輸政策新增/編輯
        alias: '/product/ebayListing/listingTemplate/shippingPolicy/create',
        component: () =>
          import(
            '@/views/product/ebay/commonTemplate/shippingPolicyDetails.vue'
          )
      },
      {
        path: '/product/ebayListing/listingTemplate/returnPolicy', // 退款政策
        component: () =>
          import('@/views/product/ebay/commonTemplate/returnPolicy.vue')
      },
      {
        path: '/product/ebayListing/listingTemplate/returnPolicy/detail', // 退款政策新增/編輯
        alias: '/product/ebayListing/listingTemplate/returnPolicy/create',
        component: () =>
          import('@/views/product/ebay/commonTemplate/returnPolicyDetails.vue')
      },
      {
        path: '/product/ebayListing/listingTemplate/goodsAddress', // 物品地址
        component: () =>
          import('@/views/product/ebay/commonTemplate/goodsAddress.vue')
      },
      {
        path: '/product/ebayListing/listingTemplate/goodsAddress/detail', // 物品地址新增/編輯
        alias: '/product/ebayListing/listingTemplate/goodsAddress/create',
        component: () =>
          import('@/views/product/ebay/commonTemplate/goodsAddressDetails.vue')
      },
      {
        path: '/product/ebayListing/listingTemplate/listingFixTemplate', // 兼容組件模板
        component: () => import('@/views/product/ebay/listingFixTemplate.vue')
      },
      {
        path: '/product/exchangeRate', // 匯率
        component: () => import('@/views/product/exchangeRate.vue')
      },
      {
        path: '/product/ebayListing/listingTemplateDetail', // 商品模板詳情
        component: () =>
          import('@/views/product/ebay/listingTemplateDetail.vue')
      },
      {
        path: '/product/lazadaListing/online', // lazada刊登在线
        component: () => import('@/views/product/lazada/onlineList.vue')
      },
      {
        path: '/product/lazadaListing/online/details', // lazada刊登在线詳情
        component: () => import('@/views/product/lazada/details.vue')
      },
      {
        path: '/product/lazadaListing/draft', // lazada刊登草稿
        component: () => import('@/views/product/lazada/draftList.vue'),
        meta: {
          type: '1'
        }
      },
      {
        path: '/product/lazadaListing/draft/create', // lazada 新增草稿
        component: () => import('@/views/product/lazada/details.vue')
      },
      {
        path: '/product/lazadaListing/draft/details', // lazada刊登草稿詳情
        component: () => import('@/views/product/lazada/details.vue')
      },
      {
        path: '/product/lazadaListing/failed', // lazada刊登草稿 發佈失敗
        component: () => import('@/views/product/lazada/draftList.vue'),
        meta: {
          type: '4'
        }
      },
      {
        path: '/product/lazadaListing/publishing', // lazada刊登草稿 發佈中
        component: () => import('@/views/product/lazada/draftList.vue'),
        meta: {
          type: '2'
        }
      },
      {
        path: '/product/shopeeListing/online', // shopee刊登在线
        component: () => import('@/views/product/shopee/onlineList.vue')
      },
      {
        path: '/product/shopeeListing/draft', // shopee刊登草稿
        component: () => import('@/views/product/shopee/draftList.vue'),
        meta: {
          type: '1'
        }
      },
      {
        path: '/product/shopeeListing/failed', // shopee刊登草稿 發佈失敗
        component: () => import('@/views/product/shopee/draftList.vue'),
        meta: {
          type: '4'
        }
      },
      {
        path: '/product/shopeeListing/publishing', //shopee刊登草稿 發佈中
        component: () => import('@/views/product/shopee/draftList.vue'),
        meta: {
          type: '2'
        }
      },
      {
        path: '/product/shopeeListing/draft/create', // shopee 新增草稿
        component: () => import('@/views/product/shopee/details.vue')
      },
      {
        path: '/product/shopeeListing/draft/details', // shopee 刊登草稿詳情
        component: () => import('@/views/product/shopee/details.vue')
      },
      {
        path: '/product/shopeeListing/online/details', // shopee 刊登在線詳情
        component: () => import('@/views/product/shopee/details.vue')
      },
      {
        path: '/product/coupangListing/online', // coupang刊登在线
        component: () => import('@/views/product/coupang/onlineList.vue')
      },
      {
        path: '/product/coupangListing/draft/create', // coupang 新增草稿
        component: () => import('@/views/product/coupang/details.vue')
      },
      {
        path: '/product/coupangListing/draft/details', // coupang 刊登草稿詳情
        component: () => import('@/views/product/coupang/details.vue')
      },
      {
        path: '/product/coupangListing/online/details', // coupang 刊登在線詳情
        component: () => import('@/views/product/coupang/details.vue')
      },
      {
        path: '/product/coupangListing/draft', // coupang刊登草稿
        component: () => import('@/views/product/coupang/draftList.vue'),
        meta: {
          type: '1'
        }
      },
      {
        path: '/product/coupangListing/failed', // coupang刊登草稿 發佈失敗
        component: () => import('@/views/product/coupang/draftList.vue'),
        meta: {
          type: '4'
        }
      },
      {
        path: '/product/coupangListing/publishing', //coupang刊登草稿 發佈中
        component: () => import('@/views/product/coupang/draftList.vue'),
        meta: {
          type: '2'
        }
      },
      {
        path: '/product/shopeeListing/marketing/promotionActivity', // shopee折扣活動列表
        component: () => import('@/views/product/shopee/promotionActivity.vue')
      },
      {
        path: '/product/shopeeListing/marketing/promotionActivity/create', // 新建shopee折扣活動
        component: () =>
          import('@/views/product/shopee/createPromotionActivity.vue')
      },
      {
        path: '/product/shopeeListing/marketing/promotionActivity/detail', // shopee折扣活動詳情
        component: () =>
          import('@/views/product/shopee/createPromotionActivity.vue')
      },
      {
        path: '/product/shopeeListing/marketing/promotionActivity/goods', // shopee折扣活動參加活動商品
        component: () => import('@/views/product/shopee/activityGoods.vue')
      },
      {
        path: '/product/shopeeListing/marketing/topPromotionOfGoods', // shopee置頂設置列表
        component: () =>
          import('@/views/product/shopee/topPromotionOfGoods.vue')
      },
      {
        path: '/product/shopeeListing/marketing/topPromotionOfGoods/setting', // shopee置頂設置
        component: () =>
          import('@/views/product/shopee/topPromotionGoodsSetting.vue')
      },
      {
        path: '/product/marketing/evaluationTemplate', // 回評模板
        component: () =>
          import('@/views/product/marketing/evaluation/evaluationTemplate.vue')
      },
      {
        path: '/product/marketing/evaluationTemplate/create', // 新增回評模板
        component: () =>
          import(
            '@/views/product/marketing/evaluation/evaluationTemplateCreate.vue'
          )
      },
      {
        path: '/product/marketing/evaluationTemplate/edit', // 編輯回評模板
        component: () =>
          import(
            '@/views/product/marketing/evaluation/evaluationTemplateCreate.vue'
          )
      },
      {
        path: '/product/marketing/autoEvaluation', // 自動回評列表
        component: () =>
          import('@/views/product/marketing/evaluation/autoEvaluationList.vue')
      },
      {
        path: '/product/marketing/autoEvaluation/detail', // 自動回評列表詳情
        component: () =>
          import(
            '@/views/product/marketing/evaluation/autoEvaluationDetail.vue'
          )
      },
      {
        path: '/product/marketing/autoEvaluation/record', // 回評記錄
        component: () =>
          import(
            '@/views/product/marketing/evaluation/autoEvaluationRecord.vue'
          )
      },
      {
        path: '/product/shopifyListing/draft', // shopify刊登草稿
        component: () => import('@/views/product/shopify/draftList.vue'),
        meta: {
          type: '1'
        }
      },
      {
        path: '/product/shopifyListing/failed', // shopify刊登草稿 發佈失敗
        component: () => import('@/views/product/shopify/draftList.vue'),
        meta: {
          type: '4'
        }
      },
      {
        path: '/product/shopifyListing/publishing', //shopify刊登草稿 發佈中
        component: () => import('@/views/product/shopify/draftList.vue'),
        meta: {
          type: '2'
        }
      },
      {
        path: '/product/shopifyListing/online', // shopify刊登在线
        component: () => import('@/views/product/shopify/onlineList.vue')
      },
      {
        path: '/product/shopifyListing/draft/create', // shopify 新增草稿
        component: () => import('@/views/product/shopify/details.vue')
      },
      {
        path: '/product/shopifyListing/draft/details', // shopify刊登草稿詳情
        component: () => import('@/views/product/shopify/details.vue')
      },
      {
        path: '/product/shopifyListing/online/details', // shopify 刊登在線詳情
        component: () => import('@/views/product/shopify/details.vue')
      },
      {
        path: '/product/shopifyListing/collections', // shopify產品集合列表
        component: () => import('@/views/product/shopify/collections.vue')
      },
      {
        path: '/product/shopifyListing/collections/create', // shopify產品集合列表新增
        component: () => import('@/views/product/shopify/collectionsCreate.vue')
      },
      {
        path: '/product/shopifyListing/collections/edit', // shopify產品集合列表修改
        component: () => import('@/views/product/shopify/collectionsCreate.vue')
      },
      {
        path: '/product/shoplineListing/draft', // shopline刊登草稿
        component: () => import('@/views/product/shopline/draftList.vue'),
        meta: {
          type: '1'
        }
      },
      {
        path: '/product/shoplineListing/failed', // shopline刊登草稿 發佈失敗
        component: () => import('@/views/product/shopline/draftList.vue'),
        meta: {
          type: '4'
        }
      },
      {
        path: '/product/shoplineListing/publishing', //shopline刊登草稿 發佈中
        component: () => import('@/views/product/shopline/draftList.vue'),
        meta: {
          type: '2'
        }
      },
      {
        path: '/product/shoplineListing/online', // shopline刊登在线
        component: () => import('@/views/product/shopline/onlineList.vue')
      },
      {
        path: '/product/shoplineListing/draft/create', // shopline 新增草稿
        component: () => import('@/views/product/shopline/details.vue')
      },
      {
        path: '/product/shoplineListing/draft/details', // shopline刊登草稿詳情
        component: () => import('@/views/product/shopline/details.vue')
      },
      {
        path: '/product/shoplineListing/online/details', // shopline 刊登在線詳情
        component: () => import('@/views/product/shopline/details.vue')
      },
      {
        path: '/product/shoplineListing/categories', // shopline產品分類列表
        component: () => import('@/views/product/shopline/categories.vue')
      },
      {
        path: '/product/shoplineListing/categories/create', // shopline產品分類列表新增
        component: () => import('@/views/product/shopline/categoriesCreate.vue')
      },
      {
        path: '/product/shoplineListing/categories/edit', // shopline產品分類列表修改
        component: () => import('@/views/product/shopline/categoriesCreate.vue')
      },
      {
        path: '/product/amazonListing/draft', // amazon刊登草稿
        component: () => import('@/views/product/amazon/draftList.vue'),
        meta: {
          type: '1'
        }
      },
      {
        path: '/product/amazonListing/failed', // amazon刊登草稿 發佈失敗
        component: () => import('@/views/product/amazon/draftList.vue'),
        meta: {
          type: '4'
        }
      },
      {
        path: '/product/amazonListing/publishing', //amazon刊登草稿 發佈中
        component: () => import('@/views/product/amazon/draftList.vue'),
        meta: {
          type: '2'
        }
      },
      {
        path: '/product/amazonListing/online', // amazon刊登在线
        component: () => import('@/views/product/amazon/onlineList.vue')
      },
      {
        path: '/product/amazonListing/draft/create', // amazon 新增草稿
        component: () => import('@/views/product/amazon/details.vue')
      },
      {
        path: '/product/amazonListing/draft/details', // amazon刊登草稿詳情
        component: () => import('@/views/product/amazon/details.vue')
      },
      {
        path: '/product/amazonListing/online/details', // amazon 刊登在線詳情
        component: () => import('@/views/product/amazon/details.vue')
      }
    ]
  }
]
