import { RouteConfig } from 'vue-router'

export const order: RouteConfig[] = [
  {
    path: '/orderInfo',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/orderInfo/order/underOrderVerification', // 核对中的订单
        component: () => import('@/views/order/underOrderVerification.vue')
      },
      {
        path: '/orderInfo/order/newHandmadeOrder', // 核对中的订单-新增手工訂單
        component: () => import('@/views/order/newHandmadeOrder.vue')
      },
      {
        path: '/orderInfo/order/cancelled', // 已取消訂單
        component: () => import('@/views/order/cancelled.vue')
      },
      {
        path: '/orderInfo/order/underOrderVerification/orderDetails/:id', // 訂單詳情
        alias: [
          '/orderInfo/order/newOrder/orderDetails/:id',
          '/orderInfo/order/cancelled/orderDetails/:id',
          '/orderInfo/order/stockout/orderDetails/:id',
          '/orderInfo/order/readyToShip/orderDetails/:id',
          '/orderInfo/order/readyToShip/orderDetails/:id',
          '/orderInfo/order/onShipment/orderDetails/:id',
          '/orderInfo/order/afterShipment/orderDetails/:id',
          '/afterSale/returnGoods/orderDetails/:id',
          '/afterSale/exchange/orderDetails/:id',
          '/afterSale/repair/orderDetails/:id',
          '/afterSale/notTaken/orderDetails/:id'
        ],
        component: () => import('@/views/order/orderDetails.vue')
      },
      {
        path: '/orderInfo/order/newOrder', // 新訂單
        component: () => import('@/views/order/newOrder.vue')
      },
      {
        path: '/orderInfo/order/stockout', // 缺貨
        component: () => import('@/views/order/stockout.vue')
      },
      {
        path: '/orderInfo/order/readyToShip', // 可出貨
        component: () => import('@/views/order/readyToShip.vue')
      },
      {
        path: '/orderInfo/order/onShipment', // 出貨中
        component: () => import('@/views/order/onShipment.vue'),
        meta: {
          type: 'onShipment'
        }
      },
      {
        path: '/orderInfo/order/onShipment/rePrint', // 出貨中-重新
        component: () => import('@/views/order/onShipment.vue'),
        meta: {
          type: 'onShipment'
        }
      },
      {
        path: '/orderInfo/order/scanSku', // 扫描SKU
        component: () => import('@/views/order/scanSku.vue')
      },
      {
        path: '/orderInfo/order/onShipment/scanShipment', // 掃描出貨
        component: () => import('@/views/order/scanShipment.vue')
      },
      {
        path: '/orderInfo/order/afterShipment', // 已出貨
        component: () => import('@/views/order/afterShipment.vue')
      },
      {
        // export and import task
        path: '/orderInfo/order/task',
        component: () => import('@/components/task.vue')
      },
      {
        path: '/orderInfo/order/orderTemplate', // 訂單導入模板
        component: () => import('@/views/order/orderTemplate.vue')
      },
      {
        path: '/orderInfo/order/orderTemplate/orderTemplateImport', // 訂單導入模板導入
        component: () => import('@/views/order/orderTemplateImport.vue')
      }
    ]
  }
]
