import { RouteConfig } from 'vue-router'

export const settings: RouteConfig[] = [
  {
    path: '/settings',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/settings/platform', // 平台管理
        component: () => import('@/views/settings/platform.vue')
      },
      {
        path: '/settings/createOrder/:id', // 購買店鋪
        component: () => import('@/views/settings/createOrder.vue'),
        meta: {
          bg: '#fff'
        }
      },
      {
        path: '/settings/result', // 結果頁
        component: () => import('@/views/settings/result.vue')
      },
      {
        path: '/settings/userCenter/paymentMes', // 付款信息
        component: () => import('@/views/settings/paymentMes.vue')
      },
      {
        path: '/settings/userCenter/valueAddedResourcePack', // 增值資源包
        component: () => import('@/views/settings/valueAddedResourcePack.vue')
      },
      {
        path: '/settings/userCenter/message', // 站內信
        component: () => import('@/views/settings/message.vue')
      },
      {
        path: '/settings/userCenter/message/details/:id', // 站內信
        component: () => import('@/views/settings/messageDetails.vue')
      },
      {
        path: '/settings/memberAccount', // 角色管理
        component: () => import('@/views/settings/memberAccount.vue')
      },
      {
        path: '/settings/warehouseSetting', // 倉庫設置
        component: () => import('@/views/settings/warehouseSetting.vue')
      },
      {
        path: '/settings/funSettings', // 功能設置
        component: () => import('@/views/settings/funSettings.vue')
      },
      {
        path: '/settings/shippingSettings', // 托運設置
        component: () => import('@/views/settings/shippingSettings.vue')
      },
      {
        path: '/settings/watermarkTemplate', // 水印模板
        component: () => import('@/views/settings/watermarkTemplate.vue')
      },
      {
        path: '/settings/watermarkTemplateDetail', // 水印模板詳情
        component: () => import('@/views/settings/watermarkTemplateDetail.vue')
      },
      {
        path: '/settings/exchangeRate', // 匯率設置
        component: () => import('@/views/settings/exchangeRate.vue')
      },
      {
        path: '/settings/task', // 任務中心
        component: () => import('@/views/settings/task.vue')
      },
      {
        path: '/settings/smsList', // 短信模板
        component: () => import('@/views/settings/smsList.vue')
      },
      {
        path: '/settings/smsRecoard', // 短信發送記錄
        component: () => import('@/views/settings/smsRecoard.vue')
      },
      {
        path: '/settings/emailList', // 郵件模板
        component: () => import('@/views/settings/emailList.vue')
      },
      {
        path: '/settings/emailRecord', //  郵件發送記錄
        component: () => import('@/views/settings/emailRecord.vue')
      },
      {
        path: '/settings/orderRules/warehouseRules', // 仓库匹配規則
        component: () =>
          import('@/views/settings/orderRules/warehouseRules.vue')
      },
      {
        path: '/settings/orderRules/smsRules', // 短信規則
        component: () => import('@/views/settings/orderRules/smsRules.vue')
      },
      {
        path: '/settings/orderRules/emailRules', // 郵件規則
        component: () => import('@/views/settings/orderRules/emailRules.vue')
      },
      {
        path: '/settings/orderRules/deliveryRules', // 物流匹配規則
        component: () => import('@/views/settings/orderRules/shippingRules.vue')
      },
      {
        path: '/settings/invoiceList', // 發票模板列表
        component: () => import('@/views/settings/invoice/list.vue')
      },
      {
        path: '/settings/invoiceList/create', // 新增發票模板
        component: () => import('@/views/settings/invoice/detail.vue')
      },
      {
        path: '/settings/invoiceList/update', // 编辑發票模板
        component: () => import('@/views/settings/invoice/detail.vue')
      }
    ]
  }
]
