import Vue from 'vue'
import Router, { RouterOptions } from 'vue-router'
import routes from './routes'
Vue.use(Router)

const rotuerObj: RouterOptions = {
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
}

export default new Router(rotuerObj)
