// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("font/iconfont.woff2?t=1676013312565", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("font/iconfont.woff?t=1676013312565", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("font/iconfont.ttf?t=1676013312565", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:iconfont;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}.iconfont{font-family:iconfont!important;font-size:16px;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-addcell:before{content:\"\\e7cc\"}.icon-setting-01:before{content:\"\\e6ce\"}.icon-g_translate_px_rounded:before{content:\"\\ed3d\"}.icon-shanghuqianyi-copy:before{content:\"\\e631\"}.icon-shangjia:before{content:\"\\e607\"}.icon-xiajia:before{content:\"\\e60c\"}.icon-dingdanguize:before{content:\"\\e600\"}.icon-duanxin1:before{content:\"\\e747\"}.icon-duanxin:before{content:\"\\e71a\"}.icon-ebay1:before{content:\"\\e617\"}.icon-LAZADA:before{content:\"\\e733\"}.icon-tuijian:before{content:\"\\e6a5\"}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
