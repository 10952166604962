import { RouteConfig } from 'vue-router'

export const member: RouteConfig[] = [
  {
    path: '/member',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/member/customerArchives', // 客戶檔案
        component: () => import('@/views/member/customerArchives.vue')
      },
      {
        // export and import task
        path: '/member/customerArchives/task',
        alias: [
          '/member/blackList/task',
          '/afterSale/returnGoods/task',
          '/afterSale/exchange/task',
          '/afterSale/repair/task',
          '/afterSale/notTaken/task'
        ],
        component: () => import('@/components/task.vue')
      },
      {
        path: '/member/blackList', // 黑名單
        component: () => import('@/views/member/blackList.vue')
      },
      {
        path: '/member/marketing', // 再行銷分頁
        component: () => import('@/views/member/marketing.vue')
      },
      {
        path: '/member/marketing/details/:id', // 再行銷分頁
        component: () => import('@/views/member/details.vue')
      },
      {
        path: '/member/marketing/createNewActivity', // 再行銷活動創建
        component: () => import('@/views/member/createNewActivity.vue')
      },
      {
        path: '/member/customerArchives/customerDetails/:id', // 客戶資料詳情
        alias: '/member/blackList/customerDetails/:id',
        component: () => import('@/views/member/customerDetails.vue')
      }
    ]
  }
]
