import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const dataState = createPersistedState({
  paths: ['currency', 'currencyList']
})
export default new Vuex.Store({
  state: {
    activeMenu: null,
    imgPrefix: '',
    filePrefix: '',
    userInfoList: null,
    roleList: '',
    userInfo: '',
    isAdmin: false,
    loginStatus: false,
    memberTitle: '',
    changeCountStatus: false,
    breadMenu: [],
    errorCount: 0,
    returnPolicyLoading: false,
    shippingPolicyLoading: false,
    paymentPolicyLoading: false,
    routePath: '',
    bgColor: '',
    managerStatus: false,
    countryList: [],
    currency: '',
    currencyCountry: '',
    currencyList: []
  },
  mutations: {
    setCurrencyList(state, data) {
      state.currencyList = data
    },
    setCurrencyCountry(state, data) {
      state.currencyCountry = data
    },
    setCurrency(state, data) {
      state.currency = data
    },
    countryList(state, data) {
      state.countryList = data
    },
    setManagerStatus(state, data) {
      state.managerStatus = data
    },
    setBgColor(state, data) {
      state.bgColor = data
    },
    routePath(state, data) {
      state.routePath = data
    },
    setReturnPolicyLoading(state, data) {
      state.returnPolicyLoading = data
    },
    setPaymentPolicyLoading(state, data) {
      state.paymentPolicyLoading = data
    },
    setShippingPolicyLoading(state, data) {
      state.shippingPolicyLoading = data
    },
    setErrorCount(state, data) {
      state.errorCount++
    },
    roleList(state, data) {
      state.roleList = data
    },
    activeMenu(state, data) {
      // 激活菜單
      state.activeMenu = data
    },
    imgPrefix(state, data) {
      state.imgPrefix = data
    },
    filePrefix(state, data) {
      state.filePrefix = data
    },
    userInfoList(state, data) {
      state.userInfoList = data
    },
    userInfo(state, data) {
      state.userInfo = data
    },
    loginStatus(state, data) {
      state.loginStatus = data
    },
    memberTitle(state, data) {
      state.memberTitle = data
    },
    changeCountStatus(state, data) {
      state.changeCountStatus = data
    },
    breadMenu(state, data) {
      state.breadMenu = data
    },
    isAdmin(state, data) {
      state.isAdmin = data
    }
  },
  actions: {},
  plugins: [dataState]
})
