import { callService } from '../index'
import api from '@/api/api'
import { RequestResponse } from '@/interface/request'

// 獲取國家基礎數據
export function getCountryListRequest<D = {}>(
  {},
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(api.get_countryList, {}, method)
}

// 获取平台所有店铺列表
export function getAllSaleAccount<D = {}, P = {}>(
  params: { platformId: string },
  method: string
): Promise<RequestResponse<D>> {
  const { platformId } = params
  return callService<D>(
    api.get_salesAccountAllList + '?platformId=' + platformId,
    {},
    method
  )
}

// login mes
export function getLoginMes<D = {}>(
  {},
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(api.get_loginMes, {}, method)
}
