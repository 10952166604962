import Cookies from 'js-cookie'
import config from '@/config'
export const TOKEN_KEY = 'token'
export const TOKEN_TIME = 'token_time'

export const setToken = (
  TOKEN,
  KEY = TOKEN_KEY,
  TOKEN_TIME = config.token_time,
  CUR_TIME = config.curTime
) => {
  Cookies.set(KEY, TOKEN)
}

export const getToken = (KEY = TOKEN_KEY) => {
  const token = Cookies.get(KEY) // hack 方案
  if (token) {
    return token
  } else {
    return false
  }
}

export const removeToken = (KEY = TOKEN_KEY) => {
  Cookies.remove(KEY)
}

export const setTokenTime = (time) => {
  let expireTime = new Date(new Date().getTime() + time)
  Cookies.set(TOKEN_TIME, time, expireTime)
}

export const getTokenTime = () => {
  return Cookies.get(TOKEN_TIME)
    ? Number(Cookies.get(TOKEN_TIME))
    : config.token_time
}
