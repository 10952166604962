import { callService } from '../index'
import api from '@/api/api'
import settingsApi from '@/api/settingsApi'
import { RequestResponse } from '@/interface/request'
import { queryParams } from '@/utils/util'

// 获取固定匯率列表
export function getExchangeRateList<D = {}>(): Promise<RequestResponse<D>> {
  return callService<D>(api.get_exchangeRateList, {})
}

// 修改匯率
export function setExchangeRate<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(api.set_fixedExchangeRate, params)
}

// 獲取任務中心列表
export function setCommonTaskList<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(api.get_commonTaskList, params)
}

// 獲取所有平台
export function getAllPlatform<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(api.get_platformList, params)
}

// 獲取已購買所有平台
export function getPurchasedPlatformListRequest<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(api.get_purchasedPlatformList, {}, method)
}

// 获取商户设置信息
export function getSetting<D = {}, P = {}>(
  params: { settingCode: string },
  method: string
): Promise<RequestResponse<D>> {
  const { settingCode } = params
  return callService<D>(api.get_setting + settingCode, {}, method)
}

// 獲取EMAIL模板信息
export function getMerchantSettingEmail<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(api.get_emailModelSettings, {}, method)
}

// 同步助手值獲取
export function getStockSync<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(api.get_stockSync, {}, method)
}

// 启用保固助手
export function setEnableWarrantySetValue<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(api.set_warrantySync, params)
}

// 設置EMAIL保固模板, 已作废
export function setEmailMode<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(api.set_emailModel, params)
}

// 同步庫存助手
export function setInventorySetValue<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(api.set_stockSync, params)
}

// 獲取短信模板列表
export function getSmsList<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_smsTemplate, params)
}

// 獲取短信發送記錄列表
export function getSmsRecordList<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_smsRecord, params)
}

// 重新發送短信
export function setReSendSms<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_reSendSms, params)
}

// 短信發送
export function sendSms<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_sendSms, params)
}

// 獲取短信規則列表
export function getSmsRuleList<D = {}, P = {}>(
  params: { type?: string; name?: string },
  method: string
): Promise<RequestResponse<D>> {
  let str = queryParams(params, true)
  return callService<D>(settingsApi.get_smsRuleList + str, {}, method)
}

// 獲取短信規則詳情
export function getSmsRuleDetail<D = {}, P = {}>(
  params: {
    autoRuleId: string
  },
  method: string
): Promise<RequestResponse<D>> {
  const { autoRuleId } = params
  return callService<D>(settingsApi.get_smsRuleDetails + autoRuleId, {}, method)
}

// 新增短信規則
export function saveSmsRule<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_newSmsRule, params, method)
}

// 刪除短信規則
export function delSmsRule<D = {}>(
  params: {
    autoRuleIdList: Array<string>
  },
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.delete_smsRule, params, method)
}

// 更新短信規則狀態
export function updateSmsRuleStatus<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_updateRuleStatus, params, method)
}

// 獲取郵件模板列表
export function getEmailList<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_emailList, params)
}

// 刪除郵件模板
export function delEmailTemplate<D = {}>(
  params: {
    emailTemplateIdList: Array<string>
  },
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.del_emailTemplate, params, method)
}

// 更新郵件模板
export function updateEmailTemplate<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_updateEmailTemplate, params, method)
}

// 獲取郵件模板詳情
export function getEmailTemplateDetail<D = {}, P = {}>(
  params: {
    emailTemplateId: string
  },
  method: string
): Promise<RequestResponse<D>> {
  const { emailTemplateId } = params
  return callService<D>(
    settingsApi.get_emailTemplateDetails + emailTemplateId,
    {},
    method
  )
}

// 新增郵件模板
export function setNewEmailTemplate<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_newEmailTemplate, params)
}

// 獲取郵件發送記錄列表
export function getEmailRecoardList<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_emailRecord, params)
}

// 重新發送郵件
export function setReSendEmail<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_reSendEmail, params)
}

// 獲取郵件規則列表
export function getEmailRuleList<D = {}, P = {}>(
  params: { type?: string; name?: string },
  method: string
): Promise<RequestResponse<D>> {
  let str = queryParams(params, true)
  return callService<D>(settingsApi.get_emailRuleList + str, {}, method)
}

// 獲取郵件規則詳情
export function getEmailRuleDetail<D = {}, P = {}>(
  params: {
    autoRuleId: string
  },
  method: string
): Promise<RequestResponse<D>> {
  const { autoRuleId } = params
  return callService<D>(
    settingsApi.get_emailRuleDetails + autoRuleId,
    {},
    method
  )
}

// 新增郵件規則
export function saveEmailRule<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_newEmailRule, params, method)
}

// 刪除郵件規則
export function delEmailRule<D = {}>(
  params: {
    autoRuleIdList: Array<string>
  },
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.delete_emailRule, params, method)
}

// 更新郵件規則狀態
export function updateEmailRuleStatus<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_updateEmailRuleStatus, params, method)
}

// 獲取不分頁郵件模板列表
export function getEmailTemplateNoPage<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_emailList, params, method)
}

// 查询仓库匹配所有規則
export function getWarehouseRulesAllList<D = {}>(
  params: {
    name?: string
    type?: string
  },
  method: string
): Promise<RequestResponse<D>> {
  console.log(queryParams(params, true))
  let str = queryParams(params, true)
  return callService<D>(settingsApi.get_warehouseRulesAllList + str, {}, method)
}

// 刪除規則
export function delWarehouseRule<D = {}>(
  params: {
    autoRuleIdList: Array<string>
  },
  method: string
): Promise<RequestResponse<D>> {
  console.log('params:', params)
  return callService<D>(settingsApi.delete_warehouseRules, params, method)
}

// 新增規則
export function saveWarehouseRule<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_newWarehouseRules, params, method)
}

// 獲取規則詳情
export function getWarehouseRuleDetail<D = {}, P = {}>(
  params: {
    autoRuleId: string
  },
  method: string
): Promise<RequestResponse<D>> {
  const { autoRuleId } = params
  return callService<D>(
    settingsApi.get_warehouseRulesDetails + autoRuleId,
    {},
    method
  )
}

// 規則排序
export function setWarehouseRulePriority<D = {}, P = {}>(
  params: {
    currentAutoRuleId: string
    nextAutoRuleId: string | null
    prevAutoRuleId: string | null
  },
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_updateWarehousePriority, params, method)
}

// 新增自定義平台set_newCustomerPlatform
export function setNewCustomerPlatform<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_newCustomerPlatform, params)
}

// 修改自定義平台
export function setUpdateCustomPlatform<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_updateCustomPlatform, params)
}

// 刪除自定義平台
export function setDeleteCustomPlatform<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_deleteCustomPlatform, params, method)
}

// 站內信列表
export function getMessageList<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_messageList, params)
}

// 站內信修改
export function setMessageUpdate<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_messageUpdate, params, method)
}

// 站內信修改
export function getMessageDetails<D = {}, P = {}>(
  params: { platformMessagId: string },
  method: string
): Promise<RequestResponse<D>> {
  const { platformMessagId } = params
  return callService<D>(
    settingsApi.get_messageDetails + platformMessagId,
    {},
    method
  )
}

// 獲取用戶倉庫列表
export function getUserWarehouseList<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_userWarehouseList, {}, method)
}

// 聯繫我們
export function setContacts<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_contacts, params)
}

// 获取商户配置
export function getSettingsRequest<D = {}, P = {}>(
  params: { settingCode: string },
  method: string
): Promise<RequestResponse<D>> {
  const { settingCode } = params
  return callService<D>(settingsApi.get_settings + settingCode, {}, method)
}

// 保存商户设置
export function setSettingCurrencyRequest<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_settingCurrency, params)
}

// 获取短信支持地区列表
export function getSMSListRequest<D = {}, P = {}>(
  params: {},
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_smsList, {}, method)
}

// 發票詳情
export function getInvoiceDetailsRequest<D = {}, P = {}>(
  params: { invoiceTemplateId: string },
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(
    settingsApi.get_invoiceDetails + params.invoiceTemplateId,
    {},
    method
  )
}

// 發票列表
export function getInvoiceListRequest<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.get_invoiceList, params)
}

// 新增發票
export function setNewInvoiceRequest<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_newInvoice, params)
}

// 修改發票
export function setUpdateInvoiceRequest<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  console.log('ppp:', params)
  return callService<D>(settingsApi.set_updateInvoice, params, method)
}

// 刪除發票
export function setDeleteInvoiceRequest<D = {}, P = {}>(
  params: P,
  method: string
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_deleteInvoice, params, method)
}

// 打印發票
export function setInvoicePrintRequest<D = {}, P = {}>(
  params: P
): Promise<RequestResponse<D>> {
  return callService<D>(settingsApi.set_invoicePrint, params)
}
