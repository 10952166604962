import { RouteConfig } from 'vue-router'

export const purchase: RouteConfig[] = [
  {
    path: '/purchase',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/purchase/orderGoods', // 叫货清单
        component: () => import('@/views/purchase/orderGoods.vue')
      },
      {
        path: '/purchase/orderGoods/generatePurchaseOrder', // 生成採購單
        component: () => import('@/views/purchase/generatePurchaseOrder.vue')
      },
      {
        path: '/purchase/factorySettings', // 廠商管理
        component: () => import('@/views/purchase/factorySettings.vue')
      },
      {
        path: '/purchase/purchaseOrder', // 採購單
        component: () => import('@/views/purchase/purchaseOrder.vue')
      },
      {
        // export and import task
        path: '/purchase/purchaseOrder/task',
        component: () => import('@/components/task.vue')
      },
      {
        path: '/purchase/purchaseOrder/createNewPurchaseOrder', // 新增採購單
        component: () => import('@/views/purchase/createNewPurchaseOrder.vue')
      },
      {
        path: '/purchase/purchaseOrder/updatePurchaseOrder/:id', // 編輯採購單
        component: () => import('@/views/purchase/createNewPurchaseOrder.vue')
      },
      {
        path: '/purchase/purchaseOrder/createNew1688PurchaseOrder', // 新增1688採購單
        component: () => import('@/views/purchase/new1688PurchaseOrder.vue')
      },
      {
        path: '/purchase/purchaseOrder/update1688PurchaseOrder/:id', // 編輯1688採購單
        component: () => import('@/views/purchase/new1688PurchaseOrder.vue')
      },
      {
        path: '/purchase/purchaseOrder/check1688PurchaseOrder/:id', // 查看1688採購單
        component: () => import('@/views/purchase/new1688PurchaseOrder.vue')
      },
      {
        path: '/purchase/purchaseOrder/checkPurchaseOrder/:id', // 查看採購單
        component: () => import('@/views/purchase/checkPurchaseOrder.vue')
      },
      {
        path: '/purchase/purchaseOrder/receiving/:id', // 收貨
        component: () => import('@/views/purchase/receiving.vue')
      },
      {
        path: '/purchase/orderGoodsSettings', // 叫貨設定
        component: () => import('@/views/purchase/orderGoodsSettings.vue')
      },
      {
        path: '/purchase/1688/auth', // 1688帳號授權
        component: () => import('@/views/purchase/ali1688Auth.vue')
      },
      {
        path: '/purchase/associatedGoods', // 供应商關聯商品
        component: () => import('@/views/purchase/bindingGoods.vue')
      },
      {
        path: '/purchase/1688/purchaseAssociation', // 1688採購關聯
        component: () => import('@/views/purchase/ali1688Binding.vue')
      }
    ]
  }
]
