
import { alias29fe4faa05e144f890cc84c3cca9daf8 } from '@/customFolder/customVueAlias.js';
import axios from 'axios'
import api from '@/api/api'
import store from '@/store'
import { setToken, getToken, setTokenTime, getTokenTime } from './cookie'
import config from '@/config'
import Vue from 'vue'
import { getQueryString, getUrlAllParams, queryParams } from './util'
import { getCountryListRequest } from '@/services/baseData'
import { RequestResponse } from '@/interface/request'
import { RESPONSE_CODE } from '@/interface/constants'
import { Message } from 'element-ui'
import { getSettingsRequest } from '@/services/settings'
Vue.component('Message', Message)
// 获取token
export const getAccessToken = (path: string) => {
  return new Promise((resolve) => {
    let ticket = getQueryString('ticket')

    axios.get(api.get_token + ticket).then((response) => {
      if (response.data.code === RESPONSE_CODE.SUCCESS) {
        setToken(response.data.datas.token)
        resolve(true)
      }
    })
  })
}

// 刷新token
export const refreshToken = (curTime: number) => {
  const timeout = getTokenTime()
  if (!getToken()) return
  if (timeout <= config.curTime) {
    axios.get(api.refresh_token + getToken()).then((response) => {
      if (response.data.code === RESPONSE_CODE.SUCCESS) {
        setTokenTime(config.token_time)
        setToken(response.data.datas.token)
        setTimeout(function () {
          refreshToken(config.curTime)
        }, config.curTime)
      }
    })
  } else {
    setTokenTime(timeout - curTime)
    setTimeout(function () {
      refreshToken(config.curTime)
    }, config.curTime)
  }
}

// 获取所有用户信息
export const getUserMes = () => {
  let userInfoList = store.state.userInfoList
  if (!userInfoList) {
    axios.get(api.get_userInfo).then((response) => {
      if (response.data.code === RESPONSE_CODE.SUCCESS) {
        if (response.data.datas !== null)
          store.commit('userInfoList', response.data.datas)
      }
    })
  }
}

export const getLoginMes = () => {
  let v = this
  return new Promise((resolve) => {
    axios
      .get(api.get_loginMes)
      .then((response) => {
        if (response.data.code === 0) {
          let data = response.data.datas
          const { language } = data
          const lang = localStorage.getItem('lang')
          if (language !== lang) {
            localStorage.setItem('lang', language)
            window.location.reload()
          }
          store.commit('userInfo', data)
          resolve(true)
        }
      })
      .catch(() => {
        resolve(true)
      })
  })
}

export const getSinglePageRoleCommon = (menuKey: string) => {
  return new Promise((resolve, reject) => {
    if (menuKey.indexOf('noPermission') > 0) {
      resolve(true)
    } else {
      axios.post(api.get_singlePageRoleCommon + menuKey).then((response) => {
        if (response.data.code === RESPONSE_CODE.SUCCESS) {
          let pos = {}
          let data = response.data.datas
          if (data != null) {
            data.forEach((n: string) => {
              let obj = { [n]: true }
              Object.assign(pos, obj)
            })
          }
          store.commit('roleList', pos)
          resolve(true)
        }
      })
    }
  })
}
export const getCountryList = async () => {
  let area: null | string = localStorage.getItem('area')
  if (area && JSON.parse(area).length) {
    store.commit('countryList', JSON.parse(area))
    return false
  }
  try {
    const res = await getCountryListRequest({}, 'GET')
    const { code, datas }: RequestResponse = res
    if (code === RESPONSE_CODE.SUCCESS) {
      store.commit('countryList', datas)
      localStorage.setItem('area', JSON.stringify(datas))
    }
  } catch (e) {
    console.log('e:', e)
    Message.error(alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000828'))
  }
}

export const getQueryMenu = () => {
  // 获取菜单权限
  axios.get(api.get_menuRole).then((response) => {
    if (response.data.code === RESPONSE_CODE.SUCCESS) {
      let roleData = response.data.datas
      sessionStorage.setItem('roleData:', roleData)
      store.commit('roleData', roleData)
    }
  })
}

export const getSettingData = (settingCode: string) => {
  return new Promise(async (resolve) => {
    try {
      const res = await getSettingsRequest({ settingCode }, 'GET')
      const { code, datas }: RequestResponse = res
      if (code === RESPONSE_CODE.SUCCESS) {
        resolve(datas)
      } else {
        resolve(false)
      }
    } catch (e) {
      resolve(false)
      console.log('e:', e)
      Message.error(alias29fe4faa05e144f890cc84c3cca9daf8.t('key1003381'))
    }
  })
}
