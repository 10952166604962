
import { alias29fe4faa05e144f890cc84c3cca9daf8 } from '@/customFolder/customVueAlias.js';
/**
 * Created by Dean on 2019/07/17.
 * http配置
 */
import axios from 'axios'
import Vue from 'vue'
import { Message, MessageBox } from 'element-ui'
import { removeToken, getToken } from '@/utils/cookie'
Vue.component('Message', Message)
Vue.component('MessageBox', MessageBox)
// axios 配置
axios.defaults.timeout = 50000
axios.defaults.baseURL = ''
axios.defaults.responseType = 'json'
// axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.delete['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.delete['Accept'] = 'application/json'
axios.defaults.headers.delete['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.defaults.headers.put['Accept'] = 'application/json '
axios.defaults.headers.get['Content-Type'] = 'application/json'
axios.defaults.headers.get['Accept'] = 'application/json '
// http request 拦截器
axios.interceptors.request.use(
  function (config) {
    var token = getToken() // hack
    if (token) {
      config.headers.Authorization = token
    } else {
      console.log('out')
    }
    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)
// http response 拦截器
axios.interceptors.response.use(
  function (response) {
    let url = window.location.href
    if (response.data.code === 999998 || response.data.code === 999997) {
      // 没有权限
      removeToken()

      if (url.indexOf('?ticket') > 0) {
        url = url.split('?ticket')[0]
      } else if (url.indexOf('?ticket') < 0 && url.indexOf('ticket') > 0) {
        url = url.split('ticket')[0]
      }

      window.location.href =
        response.data.others.loginUrl + encodeURIComponent(url)
    } else if (response.data.code === 403) {
      // 操作上没权限
      Message.error(alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000001'))
      return response
    } else if (response.data.code === 999999) {
      // 系统级错误
      Message({
        type: 'error',
        message: 'System Error',
        offset: 50,
        showClose: true
      })
      return Promise.reject(new Error(alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000002')))
    } else if (response.data.code === 999993) {
      Message.error({
        duration: 4000,
        showClose: true,
        message: response.data.message,
        dangerouslyUseHTMLString: true
      })
      return response
    } else if (response.data.code === 999990) {
      MessageBox.confirm(response.data.message, alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000003'), {
        distinguishCancelAndClose: true,
        confirmButtonText: alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000004'),
        cancelButtonText: alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000005')
      })
        .then(() => {
          if (!response.data.datas) {
            window.location.href = '/index.html#/settings/platform'
          } else {
            window.location.href =
              '/index.html#/settings/userCenter/valueAddedResourcePack'
          }
        })
        .catch((action) => {
          console.log('2')
        })
      return response
    } else {
      return response
    }
  },
  function (error) {
    const errMsg = error.toString()
    const code = errMsg.substr(errMsg.indexOf('code') + 5)
    if (code === '500') {
      Message({
        type: 'error',
        message: 'System Error',
        offset: 50,
        showClose: true
      })
      return error.response
    } else {
      return Promise.reject(error.response.data)
    }
  }
)
