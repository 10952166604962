import { RouteConfig } from 'vue-router'

export const goods: RouteConfig[] = [
  {
    path: '/goods',
    component: () => import('@/components/layout/main.vue'), // 引入主文件
    children: [
      {
        path: '/goods/category',
        component: () => import('@/views/goods/category.vue')
      },
      {
        // my goods list
        path: '/goods/myGoods',
        component: () => import('@/views/goods/myGoods.vue')
      },
      {
        path: '/goods/commodities',
        component: () => import('@/views/goods/commodities.vue')
      },
      {
        // create commodities goods
        path: '/goods/commodities/addCommoditiesGoods',
        component: () => import('@/views/goods/addCommoditiesGoods.vue')
      },
      {
        path: '/goods/commodities/updateCommoditiesGoods/:id',
        component: () => import('@/views/goods/updateCommoditiesGoods.vue')
      },
      {
        // export and import commodities task
        path: '/goods/commodities/task',
        component: () => import('@/components/task.vue')
      },
      {
        // export and import task
        path: '/goods/myGoods/task',
        component: () => import('@/components/task.vue')
      },
      {
        // create goods
        path: '/goods/myGoods/addGoods',
        component: () => import('@/views/goods/updateGoods.vue')
      },
      {
        // update goods
        path: '/goods/myGoods/updateGoods/:id',
        component: () => import('@/views/goods/updateGoods.vue')
      },
      {
        // shoplineGoods list
        path: '/goods/onlineGoods/shopline',
        alias: [
          '/goods/onlineGoods/ebay',
          '/goods/onlineGoods/shopee',
          '/goods/onlineGoods/lazada'
        ],
        component: () => import('@/views/goods/shoplineGoods.vue')
      },
      {
        // Associated with my product
        path: '/goods/onlineGoods/associate/shopline/:ProductCode',
        alias: [
          '/goods/onlineGoods/associate/ebay/:ProductCode',
          '/goods/onlineGoods/associate/shopee/:ProductCode',
          '/goods/onlineGoods/associate/lazada/:ProductCode'
        ],
        component: () => import('@/views/goods/associate.vue')
      }
    ]
  }
]
