// @ts-ignore
import pkg from 'lodash'
const { split, forEach } = pkg
// 获取url中全部参数的对象

export const getUrlAllParams = function (path: string) {
  // 解决乱码问题
  const url = decodeURI(path)
  let res: any = {}
  let url_data = split(url, '?').length > 1 ? split(url, '?')[1] : null
  console.log('url_data:', url)
  if (!url_data) return null
  var params_arr = split(url_data, '&')
  forEach(params_arr, function (item: any) {
    var key = split(item, '=')[0]
    var value = split(item, '=')[1]
    res[key] = value
  })
  return res
}

export const queryParams = function (data: any, isPrefix?: boolean) {
  isPrefix = isPrefix ? isPrefix : false
  let prefix = isPrefix ? '?' : ''
  let _result = []
  for (let key in data) {
    let value = data[key]
    // 去掉为空的参数
    if (['', undefined, null].includes(value)) {
      continue
    }
    if (value.constructor === Array) {
      value.forEach((_value) => {
        _result.push(
          encodeURIComponent(key) + '[]=' + encodeURIComponent(_value)
        )
      })
    } else {
      _result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
    }
  }

  return _result.length ? prefix + _result.join('&') : ''
}

export const getQueryString = (name: string) => {
  // 获取url里面的参数
  let reg = new RegExp('(^|&?)' + name + '=([^&]*)(&|$)', 'i')
  let r = window.location.href.substring(1).match(reg)
  if (r !== null) {
    return decodeURI(r[2])
  } else {
    return null
  }
}

export const setStorage = (key: string, value: string) => {
  value = JSON.stringify(value)
  return localStorage.setItem(key, value)
}

export const getStorage = (key: string) => {
  let localData = localStorage.getItem(key)
  if (localData) {
    return JSON.parse(localData)
  }
  return {}
}

export const clearStorage = () => {
  clearStorage()
}

export const removeStorage = (key: string) => {
  return localStorage.removeItem(key)
}
