import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/config/ElementUI'
// @ts-ignore
import axios from 'axios'
import api from '@/api/api'
import VueClipboard from 'vue-clipboard2'
import '@/style/index.less'
import '@/style/scss/element-variables.scss'
import '@/style/iconfont.less'
import i18n from '@/locale/'
import { t } from '@/locale/method/index'
import '@/assets/http'
import globalMixin from '@/components/mixin/globalMixin'
import {
  getAccessToken,
  refreshToken,
  getSinglePageRoleCommon,
  getCountryList,
  getLoginMes
} from '@/utils/user'
// @ts-ignore
import elementEnLocale from 'element-ui/lib/locale/lang/en'
// @ts-ignore
import elementZhLocale from 'element-ui/lib/locale/lang/zh-TW'
// @ts-ignore
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import themeUtil from '@/utils/theme'
import config from '@/config'
import { getUrlAllParams, queryParams } from './utils/util'
import { getToken } from './utils/cookie'

let url = window.location.href
let params = getUrlAllParams(url)
if (params && params.ticket && getToken()) {
  delete params.ticket
  let paramsStr = queryParams(params, true)

  window.location.href = url.split('?')[0] + (paramsStr ? paramsStr : '')
}

const messages: any = {
  'zh-TW': {
    ...elementZhLocale
  },
  ja: {
    ...elementJaLocale
  },
  'en-US': {
    ...elementEnLocale
  }
}
for (let i in messages) {
  i18n.mergeLocaleMessage(i, messages[i])
}
let isNewCountry = localStorage.getItem('isNewCountry')
if (!isNewCountry) {
  localStorage.removeItem('area')
  localStorage.setItem('isNewCountry', '1')
}
if (localStorage.getItem('theme') === 'pink') {
  window.document.documentElement.setAttribute('data-theme', 'pink')
} else {
  window.document.documentElement.setAttribute('data-theme', 'blue')
}
Vue.use(ElementUI, {
  i18n: (key: string, value: string) => i18n.t(key, value)
})
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.prototype.store = store
Vue.prototype.axios = axios
if (
  localStorage.getItem('area') &&
  localStorage.getItem('version') !== config.version
) {
  localStorage.removeItem('area')
  localStorage.setItem('version', config.version)
}
function getPrefix() {
  return new Promise((resolve) => {
    let imgPrefix = store.state.imgPrefix
    if (imgPrefix) {
      resolve(true)
    } else {
      axios.get(api.get_prefix).then((response: any) => {
        if (response.data.code === 0) {
          store.commit('imgPrefix', response.data.datas.imgPrefix)
          localStorage.setItem('imgPrefix', response.data.datas.imgPrefix)
          store.commit('filePrefix', response.data.datas.filePrefix)
          localStorage.setItem('filePrefix', response.data.datas.filePrefix)
          resolve(true)
        }
      })
    }
  })
}

const CheckLog = () => {
  return new Promise(async (resolve) => {
    const url = window.location.href
    let params = getUrlAllParams(url)
    if (params && params['ticket']) {
      const result = await getAccessToken(params['ticket'])
      console.log('999')
      if (result) {
        delete params.ticket
        console.log('pppp:', params)
        const paramsStr = queryParams(params, true)
        console.log('paramsStr:', paramsStr)
        console.log('rrru:', url.split('?')[0])
        console.log('uramsStr:', url.split('?')[0] + paramsStr)
        window.location.href = url.split('?')[0] + paramsStr
        window.location.reload()
        if (getToken()) {
          setTimeout(function () {
            refreshToken(config.curTime)
          }, config.curTime)
        }

        if (url.indexOf('ticket') < 0) {
          resolve(true)
        }
        return
      }
    } else {
      console.log('??????????????')
      if (getToken()) {
        await refreshToken(0)
      }

      resolve(true)
    }
  })
} // 检查是否登录

router.beforeEach(async (to, from, next) => {
  store.commit('activeMenu', to.path)
  let menuKey = getMenuKey(to.path)
  console.log('6')
  await CheckLog()
  store.commit('routePath', to.path)
  store.commit('setManagerStatus', false)
  if (to.meta && to.meta.bg) {
    store.commit('setBgColor', to.meta.bg)
  } else {
    store.commit('setBgColor', '')
  }
  console.log('111')

  Promise.all([getPrefix()]).then((result) => {
    if (result[0]) {
      if (localStorage.getItem('theme')) {
        themeUtil.init(localStorage.getItem('theme'))
      }
      if (!store.state.userInfo) {
        getLoginMes().then((result) => {
          if (result) {
            getCountryList()
            getSinglePageRoleCommon(menuKey).then((result1) => {
              if (result1) {
                const { path } = to
                const { code } = to.query
                if (path === '/settings/platform' && code) {
                  let str = queryParams(to.query, true)
                  next('/settings/result' + str)
                }
                next()
              }
            })
          }
        })
      } else {
        getSinglePageRoleCommon(menuKey).then((result1) => {
          if (result1) {
            const { path } = to
            const { code } = to.query
            if (path === '/settings/platform' && code) {
              let str = queryParams(to.query, true)
              next('/settings/result' + str)
            }
            next()
          }
        })
      }
    }
  })
})

function getMenuKey(path: any) {
  let arr = path.split('/')
  let str = ''
  if (
    arr.includes('orderDetails') ||
    arr.includes('associate') ||
    arr.includes('updateGoods') ||
    arr.includes('updatePurchaseOrder') ||
    arr.includes('customerDetails') ||
    arr.includes('receiving') ||
    (arr.length > 2 && arr[arr.length - 2] === 'details') ||
    arr.includes('checkPurchaseOrder') ||
    arr.includes('updateCommoditiesGoods') ||
    arr.includes('update1688PurchaseOrder')
  ) {
    arr.pop()
    arr.shift()
    str = arr.join('_')
  } else if (arr === 'noPermission') {
    str = 'noPermission'
  } else {
    arr.shift()
    str = arr.join('_')
  }
  return str ? str : 'index'
}
Vue.t = t
Vue.mixin(globalMixin)
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
